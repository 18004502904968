import {Component, Injectable} from '@angular/core';
import {MatPaginatorIntl } from '@angular/material/paginator';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable()
export class LanguagesPaginatorComponent implements MatPaginatorIntl {
    changes = new Subject<void>();
    translate : TranslocoService;
    firstPageLabel = 'First page';
    itemsPerPageLabel = 'Items per page:';
    lastPageLabel = 'Last page';

    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';

    injectTranslateService(translate: TranslocoService) {
        this.translate = translate;
        let tr : Translation = this.translate.getTranslation(this.translate.getActiveLang());
        this.firstPageLabel = (tr["PAGINATOR.FIRST_PAGE"]!=undefined)?tr["PAGINATOR.FIRST_PAGE"]:this.firstPageLabel;
        this.itemsPerPageLabel = (tr["PAGINATOR.ITEMS_PER_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
        this.lastPageLabel = (tr["PAGINATOR.LAST_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
        this.nextPageLabel = (tr["PAGINATOR.NEXT_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
        this.previousPageLabel = (tr["PAGINATOR.PREVIOUS_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;

       

    }   
    getRangeLabel(page: number, pageSize: number, length: number): string {
        let tr : Translation = this.translate.getTranslation(this.translate.getActiveLang());
        
        this.firstPageLabel = (tr["PAGINATOR.FIRST_PAGE"]!=undefined)?tr["PAGINATOR.FIRST_PAGE"]:this.firstPageLabel;
        this.itemsPerPageLabel = (tr["PAGINATOR.ITEMS_PER_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
        this.lastPageLabel = (tr["PAGINATOR.LAST_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
        this.nextPageLabel = (tr["PAGINATOR.NEXT_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
        this.previousPageLabel = (tr["PAGINATOR.PREVIOUS_PAGE"]!=undefined)?tr["PAGINATOR.ITEMS_PER_PAGE"]:this.itemsPerPageLabel;
       
        if (length === 0) {
        return (tr["PAGINATOR.PAGE_1_OF_1"]!=undefined)?tr["PAGINATOR.PAGE_1_OF_1"]:'Page 1 of 1';
        }
        const amountPages = Math.ceil(length / pageSize);
        let str : string = 'Page ${page + 1} of ${amountPages}';
        if(tr["PAGINATOR.PAGE_N_OF_TOTAL"]!=undefined){
            //console.log(tr["PAGINATOR.PAGE_N_OF_TOTAL"]);
            str = tr["PAGINATOR.PAGE_N_OF_TOTAL"];
            str=str.replace("{{n}}",(page + 1)+"");
            str=str.replace("{{total}}",amountPages+"");
            //console.log(str);
        }
        return str;
    }
}

/**
 * @title Paginator internationalization
 */
@Component({
  selector: 'languages-paginator',
  templateUrl: 'languages-paginator.component.html',
})
export class LanguagesPaginator {}
