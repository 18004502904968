
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagesPaginator, LanguagesPaginatorComponent } from './languages-paginator.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';




@NgModule({
	declarations: [
		LanguagesPaginator
	],
	imports: [
		CommonModule,
        MatPaginatorModule

	
		
	],
    
})
export class LanguagesPaginatorModule { }
