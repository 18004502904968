import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    {
        path       : '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'pages/viewer', redirectTo: 'viewer'},
            {path: 'pages/viewer/:scenario/:version', redirectTo: 'viewer/:scenario/:version'},
            {path: 'pages/viewer/:scenario/:library/:version/:scene/:token', redirectTo: 'viewer/:scenario/:library/:version/:scene/:token'},
            {path: 'pages/viewer/:scenario/:library/:version/:scene/:token/:mode', redirectTo: 'viewer/:scenario/:library/:version/:scene/:token/:mode'},
            {path: 'pages/viewer/:scenario/:library/:version/:scene/:token/:mode/:scorm', redirectTo: 'viewer/:scenario/:library/:version/:scene/:token/:mode/:scorm'},
            {path: 'viewer', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'viewer/:scenario/:version', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'viewer/:scenario/:version/:time', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'viewer/:scenario/:library/:version/:scene/:token', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'auth/:scenario/:scene/:version/:time/:reason/:isAuth', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'viewer/:scenario/:library/:version/:scene/:token/:mode', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'scorm/:scenario/:version/:scorm', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'scorm/:scenario/:version/:scorm/:scene', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    },
];
