import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { 
    setPersistence, 
    signInWithEmailAndPassword, 
    browserLocalPersistence, 
    signOut, 
    createUserWithEmailAndPassword, 
    updateProfile, 
    updatePassword, 
    sendPasswordResetEmail, 
    confirmPasswordReset, 
    getAuth, 
    signInWithPopup, 
    GoogleAuthProvider, 
    FacebookAuthProvider,
    OAuthProvider,
    getAdditionalUserInfo,
    signInAnonymously,
    deleteUser,
    User
} from 'firebase/auth';
import { refFromURL } from 'firebase/database';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    public get authenticated(): boolean {
        return this._authenticated;
    }
    public set authenticated(value: boolean) {
        this._authenticated = value;
    }

    auth;
    private _newUser = false;
    _lastErrorCode;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    init(auth){
        this.auth = auth;
    }
    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(email: string, password: string, rememberMe: boolean): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
       
        
        return from(new Promise(
            (resolve, reject) => {
                
                setPersistence(this.auth, browserLocalPersistence).then(()=>
                signInWithEmailAndPassword(this.auth, email, password).then(
                    ()=>{
                        // Set the authenticated flag to true
                        this._authenticated = true;
                        resolve(true);
                    },
                    (error)=>{
                        resolve(error);
                    }
                ));
            }
        ));
    }


    public get newUser(): boolean {
        return this._newUser;
    }


    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    /**signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }**/

    signOut(): Observable<any>
    {
        return from(new Promise(
            (resolve, reject) => {
                signOut(this.auth).then(
                () => {
                    this._authenticated = false;
                    resolve(true);
                },
                (error) => {
                    reject(error);
                }
                );
            }
        ));
       
    }


    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }


    signInWithMicrosoft() {
        const provider = new OAuthProvider('microsoft.com');
        return signInWithPopup(this.auth, provider).then((result) => {

            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            const details = getAdditionalUserInfo(result);
            //console.log(details);
            this._authenticated = true;
        }).catch((error) => {

            this._lastErrorCode=error.code;
            //console.log('error');
            //console.log(error);
            // ...
        });
    }

    signInWithGoogle() {
       
        const provider = new GoogleAuthProvider();
        return signInWithPopup(this.auth, provider)
        .then((result) => {
            //console.log('result');
            //console.log(result);
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            //console.log(credential);
            const token = credential.accessToken;

            // This gives you a Google Access Token. You can use it to access the Google API.
            //const credential = GoogleAuthProvider.credentialFromResult(result);
            //const token = credential.accessToken;
            // The signed-in user info.
            //console.log('user');
            const details = getAdditionalUserInfo(result)
            //console.log(details);//.isNewUser);
            const user = result.user;
            this._authenticated = true;
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            //console.log('error');
            //console.log(error);
            this._lastErrorCode=error.code;
          
            // ...
        });
    }
    


    
    signInWithFacebook()
    {
        const provider = new FacebookAuthProvider();
        return signInWithPopup(this.auth, provider)
        .then((result) => {
            //console.log('result');
            //console.log(result);
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const details = getAdditionalUserInfo(result)
            //console.log(details);//.isNewUser);
            const user = result.user;
            this._authenticated = true;
            
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            //console.log('error');
            //console.log(error);
            this._lastErrorCode=error.code;
         
            // ...
        });
    }

    signInWithApple()
    {
        const provider = new OAuthProvider('apple.com');
        provider.setCustomParameters({
            // Localize the Apple authentication screen in French.
            locale: 'fr'
        });
          
        return signInWithPopup(this.auth, provider)
        .then((result) => {
            //console.log('result');
            //console.log(result);
            // The signed-in user info.
            const details = getAdditionalUserInfo(result)
            //console.log(details);//.isNewUser);
            const user = result.user;
        
            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
           
            this._authenticated = true;

            // IdP data available using getAdditionalUserInfo(result)
            // ...
        })
        .catch((error) => {
            //console.log('error');
            //console.log(error);
            this._lastErrorCode=error.code;
      
          // ...
        });
    }

    createNewUser(email: string, password: string, username: string) {
		return new Promise(
		(resolve, reject) => {

			createUserWithEmailAndPassword(this.auth, email, password).then(
			() => {
				updateProfile(this.auth.currentUser, {
					displayName: username
				});
                this._authenticated = true;
                resolve(undefined);
			}, 
			(error) => {
				reject(error);
			}
			);
		}
		);
	}

    changePassword(newPassword: string) {
		const user = this.auth.currentUser;
		return new Promise(
			(resolve, reject) => {
				updatePassword(user,newPassword).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

    sendResetPasswordEmail(email: string) {
		return new Promise(
			(resolve, reject) => {
				sendPasswordResetEmail(this.auth,email).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

	passwordReset(code: string, newPassword: string) {
		return new Promise(
			(resolve, reject) => {
				confirmPasswordReset(this.auth,code, newPassword).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

	getUID(){
		return this.auth.currentUser.uid;
	}

	getEmail() {
		return this.auth.currentUser.email;
	}

    /**
     * Authentification anonyme
     */
    signInAnonymously(): Promise<any> {
        return signInAnonymously(this.auth)
            .then((result) => {
                this._authenticated = true;
                return result;
            })
            .catch((error) => {
                console.error('Erreur d\'authentification anonyme :', error);
                throw error;
            });
    }

    /**
     * Supprimer un utilisateur anonyme
     */
    deleteAnonymousUser(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const currentUser: User | null = this.auth.currentUser;
    
            if (currentUser && currentUser.isAnonymous) {
                deleteUser(currentUser)
                    .then(() => {
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                console.log("L'utilisateur n'est pas anonyme ou n'est pas connecté.");
                resolve(false);
            }
        });
    }

}
